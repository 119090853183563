.tooltip {
  position: relative;
}

.tooltip-trigger {
  position: absolute;
  right: 0px;
  width: 15px;
  height: 15px;
  background: #2196F3;
  color: #FFF;
  border-radius: 50%;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'ProximaNova-SemiBold';
  z-index: 300;
  user-select: none;
}

.tooltip-bubble {
  /* min-width: 120px;
  max-width: 280px; */
  width: 280px;
  position: absolute;
  z-index: 800;
  &::after {
    content: '';
    position: absolute;
  }
}

.tooltip-top {
  bottom: 100%;
  left: 50%;
  padding-bottom: 20px;
  transform: translateX(-50%);

  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #2196F3;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tooltip-bottom {
  top: 100%;
  left: 50%;
  padding-top: 20px;
  transform: translateX(-50%);

  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #2196F3;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tooltip-left {
  top: 50%;
  right: 100%;
  padding-right: 20px;
  transform: translateY(-50%);

  &::after {
    border-left: 9px solid #2196F3;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.tooltip-right {
  top: 50%;
  left: 100%;
  padding-left: 20px;
  transform: translateY(-50%);

  &::after {
    border-right: 9px solid #2196F3;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.tooltip-message {
  background: #2196F3;
  color: #FFF;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px;
  text-align: center;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
  border-radius: 4px;
}
